import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
////const { CQ_tarif } = require("@/components/CarrotQuest/CarrotQuest.js");

export default {
  async created() {
    ////let user =
    await this.$store.dispatch("user/getUser");
    ////console.log('Success.11: user=',user)

    try {
      await this.trackGoal();
    } catch (e) {
      console.error(e);
    }
    if (this.$store.state.user.user) {
      this.$router.push({
        name: "ProfilePlan"
      });
    } else {
      this.$router.push("/");
    }

    ////window.sessionStorage.setItem("CQ_tarif", 0)
    ////CQ_tarif(this.$api, user)
  },
  methods: {
    async trackGoal() {
      const id = this.$route.query.InvId;
      if (!id) {
        return;
      }
      const {
        order
      } = await this.$store.dispatch("billing/getOrder", {
        id
      });
      await Promise.all(order.items.map(item => {
        var _item$plan;
        const goals = {
          1: "pay_start",
          2: "pay_business",
          3: "pay_pro"
        };
        const goal = goals[(_item$plan = item.plan) === null || _item$plan === void 0 ? void 0 : _item$plan.main_position];
        if (!goal) {
          return;
        }
        if (goal) {
          return this.$telemetry.reachGoal(goal);
        }
      }));
      // //console.log(this.$metrika, order);
    }
  }
};